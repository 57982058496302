<template>
  <div class="audit-container">
    <div class="head">
      <el-form inline :model="form">
        <el-form-item label="用户id">
          <el-input v-model="form.user_id"></el-input>
        </el-form-item>
        <el-form-item label="任务id">
          <el-input v-model="form.job_id"></el-input>
        </el-form-item>
        <el-form-item label="通过状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="所有" :value="null"></el-option>
            <el-option
              v-for="item in AUDIT_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">筛选</el-button>
        </el-form-item>
      </el-form>
      <el-dropdown @command="auditSelection">
        <el-button type="success" :disabled="selectedRow.length === 0">
          审核选中<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template slot="dropdown">
          <el-dropdown-menu>
            <el-dropdown-item :command="1">通过</el-dropdown-item>
            <el-dropdown-item :command="2">不通过</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-table
      stripe
      border
      :data="list"
      style="width: 100%"
      height="calc(100% - 95px )"
      @selection-change="selectionChange"
      v-loading="loading"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        align="center"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="headUrl"
        label="发布人头像"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <img style="width: 100%" :src="scope.row.headUrl" />
        </template>
      </el-table-column>
      <el-table-column
        prop="loverHeadUrl"
        label="另一半头像"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <img style="width: 100%" :src="scope.row.loverHeadUrl" />
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="发布人昵称"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="loverName"
        label="另一半昵称"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column label="发布内容" align="center">
        <template slot-scope="scope">
          <div class="content-column">
            <span>{{ scope.row.content }}</span>
            <img
              v-if="scope.row.pic"
              style="height: 80px"
              :src="
                ((JSON.parse(scope.row.pic).photos || [])[0] || {}).main_url
              "
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="jobId" label="任务ID" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="jobTitle"
        label="任务标题"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        label="发布时间"
        align="center"
        width="180"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="通过状态"
        align="center"
        width="100"
        :formatter="statusFormatter"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 2 || scope.row.status === 0"
            type="text"
            @click="auditRow(scope.row, 1)"
            >通过</el-button
          >
          <el-button
            v-if="scope.row.status === 1 || scope.row.status === 0"
            type="text"
            @click="auditRow(scope.row, 2)"
            >不通过</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { auditList, auditModification } from '@/api/loveAdvice';
import { AUDIT_STATUS } from './constant';
import moment from 'moment';
export default {
  data () {
    return {
      AUDIT_STATUS,
      pageSize: 10,
      pageNo: 1,
      total: 0,
      form: {
        user_id: null,
        job_id: null,
        status: null
      },
      loading: false,
      selectedRow: [],
      list: []
    };
  },
  created () {
    this.getList();
  },
  methods: {
    statusFormatter (row, column, cellValue, index) {
      return AUDIT_STATUS.find((i) => i.value === cellValue).label;
    },
    selectionChange (selection) {
      this.selectedRow = selection;
    },
    auditRow (row, status) {
      this.audit(row.id, status);
    },
    auditSelection (status) {
      let ids = this.selectedRow
        .filter((i) => i.status !== status)
        .map((i) => i.id)
        .join(',');
      if (ids) {
        this.audit(ids, status);
      } else {
        this.$message({
          type: 'success',
          message: '审核成功！'
        });
        this.getList();
      }
    },
    formatTime (row, column, cellValue, index) {
      return moment(cellValue).format('YYYY-MM-DD HH:mm:ss');
    },
    async audit (ids, status) {
      try {
        let data = {
          ids,
          status
        };

        const resp = await auditModification(data);
        console.log('audit resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.$message({
          type: 'success',
          message: '审核成功！'
        });
        this.getList();
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '审核失败！'
        });
      }
    },
    handleSizeChange (size) {
      if (size) {
        this.pageSize = size;
        this.pageNo = 1;
        this.getList();
      }
    },
    handleCurrentChange (current) {
      if (current) {
        this.pageNo = current;
        this.getList();
      }
    },
    async getList () {
      console.log(this.form);
      this.loading = true;
      try {
        let data = {
          user_id: this.form.user_id,
          job_id: this.form.job_id,
          page: this.pageNo,
          count: this.pageSize,
          status: this.form.status
        };
        const resp = await auditList(data);
        console.log('auditlist resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.total = resp.total;
        this.list = resp.list;
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '拉取审核列表失败'
        });
      }
      this.loading = false;
    }
  }
};
</script>
<style lang="scss">
.audit-container {
  background: #fff;
  width: calc(100% - 24px);
  height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .content-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .el-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.taskadd-dialog {
  .state-select {
    width: 100%;
  }
  .avatar-item {
    .el-form-item__content {
      display: flex;
      align-items: center;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        & > span {
          // color: #f74769;
          font-size: 12px;
        }
        & + div {
          margin-left: 30px;
        }
      }
    }
  }
  .name-item {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-input + .el-input {
        margin: 0 5px;
      }
    }
  }
}
</style>