import httpRequest from '@/utils/network/request';
import axios from 'axios';
import app from '@/config/app.js';

export const uploadPhoto = function (photo) {
  let formdata = new FormData();  // 创建form对象
  formdata.append('photo', photo);  // 通过append向form对象添加数据
  let config = {
    headers: {'Content-Type': 'multipart/form-data'}
  };
  return axios.post(app.apiUrl + '/manage/life/group/photo', formdata, config);
};

// 标签列表
export const tagList = function (params) {
  const url = '/love_advice/tag/list';
  return httpRequest.post(url, params);
};

// 标签新增
export const tagCreation = function (params) {
  const url = '/love_advice/tag/add';
  return httpRequest.post(url, params);
};

// 任务列表
export const taskList = function (params) {
  const url = '/love_advice/job/list';
  return httpRequest.post(url, params);
};

// 任务新增
export const taskCreation = function (params) {
  const url = '/love_advice/job/add';
  return httpRequest.post(url, params);
};

// 任务修改
export const taskModification = function (params) {
  const url = '/love_advice/job/update';
  return httpRequest.post(url, params);
};

// 头像列表
export const avatarList = function (params) {
  const url = '/haiyun/cp_header/list';
  return httpRequest.post(url, params);
};
// 昵称列表
export const nicknameList = function (params) {
  const url = '/haiyun/cp_name/list';
  return httpRequest.post(url, params);
};

// 示例列表
export const exampleList = function (params) {
  const url = '/love_advice/sample/list';
  return httpRequest.post(url, params);
};

// 示例新增
export const exampleCreation = function (params) {
  const url = '/love_advice/sample/add';
  return httpRequest.post(url, params);
};

// 示例修改
export const exampleModification = function (params) {
  const url = '/love_advice/sample/update';
  return httpRequest.post(url, params);
};

// 审核列表
export const auditList = function (params) {
  const url = '/love_advice/audit/list';
  return httpRequest.post(url, params);
};

// 审核列表
export const auditModification = function (params) {
  const url = '/love_advice/audit/updateStatus';
  return httpRequest.post(url, params);
};