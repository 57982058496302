export const TAGS = [
  {
    label: '测试标签',
    value: 1
  },
  {
    label: '测试标签2',
    value: 2
  },
  {
    label: '测试标签3',
    value: 3
  }
];
export const SOURCES = [
  {
    label: '用户',
    value: 0
  },
  {
    label: '运营',
    value: 1
  }
];

export const DEFAULT_PIC =
  'https://cdn.welove520.com/static/res/vue/client-service-config/default-pic.png';
export const AUDIT_STATUS = [
  {
    label: '不通过',
    value: 2
  },
  {
    label: '通过',
    value: 1
  },
  {
    label: '未审核',
    value: 0
  }
];
export const ONLINE_STATUS = [
  {
    label: '下线',
    value: 0
  },
  {
    label: '上线',
    value: 1
  }
];
export const BUBBLE_IMG_TYPE = [
  {
    label: '默认图片',
    value: 1
  },
  {
    label: '自定义图片',
    value: 2
  }
];
